<template>
  <div dir="rtl" id="printableArea" style="width: 100%;">
    <h2 style="text-align: center;">רשימת אירועים נכון לתאריך {{ currentDateString }}</h2>
    <table style="width: 100%; table-layout: auto; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="border: 1px solid #000; padding: 10px; text-align: right;">שם האירוע</th>
          <th style="border: 1px solid #000; padding: 10px; text-align: right;">תאריך האירוע</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.title" style="page-break-inside: avoid;">
          <td style="border: 1px solid #000; padding: 10px;">{{ event.title }}</td>
          <td style="border: 1px solid #000; padding: 10px;">{{ israeli_format_Date_string(event.start) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue';
import {israeli_format_Date_string} from '../../../../Methods/Dates'

export default {
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    let currentDate = new Date();
    let currentDateString = ref(currentDate.toLocaleDateString());

    

    const printTable = () => {
        // Create a new window
        let printWindow = window.open('', '', 'width=600,height=600');

        // Get the table outer HTML
        let tableHTML = document.querySelector("#printableArea").outerHTML;

        // Write the table HTML to the new window document
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write(tableHTML);
        printWindow.document.write('</body></html>');
        printWindow.print();
        printWindow.close();
    }

    return {
      currentDateString,
      printTable,
      israeli_format_Date_string
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  table {
    border: 0; 
  }

  table thead {
    display: none;
   
  }

  table tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
    
    
  }

  table td {
    display: block;
    text-align: right;
    border-bottom: 1px dotted #ccc;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  #printableArea, #printableArea * {
    visibility: visible;
  }

  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
