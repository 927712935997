<template>
  <div class="calendar-main">
    <div class="calendar-main-header">
        <h2>יומן פעילות</h2>
        <i @click="handle_print_future_events" class="material-icons print-future-event">print</i>
    </div>
    <div class="calendar-wrapper">
        <FullCalendar class="calendar-component"  :options="calendarOptions" />
    </div>

    <ChooseDateDialog 
        @close="show_create_event_dialog=false" 
        v-if="show_create_event_dialog" 
        :date_data="selected_date_data"
        @submit_new_event="handle_submit_new_event"
        :branches="branches"
    />

    <ShowEventDialog 
        v-if="selected_event_data" 
        @close="selected_event_data=null" 
        :event_data="selected_event_data"
        @update_event="handle_update_event"
        @delete_event="handle_delete_event"
        @submit_edit="handle_submit_edit"
        @submit_approved="handle_submit_approved"
        @submit_upload_image="handle_submit_upload_image"
    />
    <PrintEventsComp ref="print_comp" :events="selected_future_events" />
  </div>
</template>

<script>
import { defineAsyncComponent, ref, watch } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid'
import InteractionPlugin  from '@fullcalendar/interaction'
import illLocale from '@fullcalendar/core/locales/he';
import TimeGridPlugin from '@fullcalendar/timegrid'
import ListPlugin from '@fullcalendar/list'
import {set_new_event_to_db,reload_events_from_db,reload_events_from_db_if_authorized,get_future_events} from './script'
import {slide_pop_error} from '../../../Methods/Msgs'
import {user,role} from '../../../Methods/Utils'
import {get_full_branches_from_db} from '../../../Methods/rosman_shivuk_funcs'
import PrintEventsComp from './components/PrintEventsComp.vue'
import { FireStore, projectFirestore } from '../../../firebase/config';
export default {
    components:{
        FullCalendar,
        ChooseDateDialog:defineAsyncComponent(() => import('./components/ChooseDateDialog.vue')),
        ShowEventDialog:defineAsyncComponent(() => import('./components/ShowEventDialogg.vue')),
        PrintEventsComp,
    },
    setup(){
        
        const print_comp = ref(null)

        const selected_future_events = ref([])

        const selected_event_data = ref(null)

        const selected_date_data = ref(null)

        const show_create_event_dialog = ref(false)

        const branches = ref([])

        const handle_date_click = (e) => {
            if(role.value >= 99){
                if (e.view.type !== 'dayGridMonth') {
                    return; // Do nothing and prevent the dateClick event from triggering
                }
                console.log(e);
                selected_date_data.value = e
                show_create_event_dialog.value = true
            }
        }

        const handle_event_click = (arg) => {
            const { start, title,id,startStr,backgroundColor } = arg.event;

            selected_event_data.value = {
                backgroundColor,
                start,
                title,
                id,
                startStr,
                ...arg.event.extendedProps
            }
        }

        const handle_update_event = ({id,backgroundColor}) => {
            const index = calendarOptions.value.events.findIndex(e => e.id == id)
            console.log(index);
            if(index != -1){
                calendarOptions.value.events[index].backgroundColor = backgroundColor
            }
        }

        const handle_delete_event = (event_uid) => {
            const index = calendarOptions.value.events.findIndex(e => e.id == event_uid)
            if(index!=-1){
                calendarOptions.value.events.splice(index,1)
                selected_event_data.value = null
            }
        }

        const handle_submit_edit = ({id,description}) => {
            const index = calendarOptions.value.events.findIndex(e => e.id == id)
            if(index != -1){
                calendarOptions.value.events[index].description = description
            }
        }

        const handle_submit_approved = ({id,approved}) => {
            const index = calendarOptions.value.events.findIndex(e => e.id == id)
            if(index != -1){
                calendarOptions.value.events[index].approved = approved
            }
        }

        const calendarOptions = ref({
            plugins: [dayGridPlugin, InteractionPlugin ,ListPlugin,TimeGridPlugin ],
            initialView: 'dayGridMonth',
            locales: [illLocale],
            headerToolbar: {
                left: 'prev,today,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            dateClick: handle_date_click,
            events: [
                
            ],
            eventClick: handle_event_click,
        });

        const handle_submit_new_event = async(event_details) => {
            try{
                const id = await set_new_event_to_db(event_details)
                console.log(id);
                calendarOptions.value.events.push({...event_details,id})
                show_create_event_dialog.value = false
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const handle_submit_upload_image = ({id,media}) => {
            const index = calendarOptions.value.events.findIndex(e => e.id == id)
            if(index != -1){
                calendarOptions.value.media = media
            }

        }

       const handle_print_future_events = async () => {
            if(print_comp.value) {
                selected_future_events.value = await get_future_events();
                console.log(selected_future_events.value);
                // Wait for Vue to finish updating after data change
                setTimeout(async()=>{
                    print_comp.value.printTable();
                },700)
            }
        }

        const init = async() => {
            try{
                if(role.value){
                    branches.value = await get_full_branches_from_db()
                    if(role.value >= 99 || user.value?.branche_num == 30){
                        calendarOptions.value.events = await reload_events_from_db()
                    }else{
                        calendarOptions.value.events = await reload_events_from_db_if_authorized(user.value.branche_num)
                    }
                }
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        init()
        
        watch(role,async()=>{
            init()
        })
       
        
        return{
            calendarOptions,
            handle_date_click,
            handle_event_click,
            show_create_event_dialog,
            selected_date_data,
            handle_submit_new_event,
            init,
            selected_event_data,
            handle_update_event,
            handle_delete_event,
            handle_submit_edit,
            handle_submit_approved,
            handle_submit_upload_image,
            branches,
            handle_print_future_events,
            selected_future_events,
            print_comp
        }
    }
}
</script>

<style scoped>
    .calendar-main {
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
    }

    .calendar-main-header {
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .calendar-wrapper {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: calc(100% - 50px);
        margin: 0 auto;
        overflow: hidden;
    }

    .calendar-wrapper .calendar-component {
        height: 100%;
        width: 100%;
    }

    ::v-deep(.fc .fc-toolbar.fc-header-toolbar){
            margin-bottom: unset !important;
    }
    ::v-deep(.fc-daygrid-day-frame ){
        cursor: pointer;
    }
    ::v-deep(.fc-event) {
        border: none !important;
        box-shadow: none !important;
        background-clip: padding-box !important;
    }
    @media (max-width: 600px) {
        ::v-deep(.fc-button-group > button) {
             /* Adjust the padding to make the buttons smaller */
            font-size: 12px !important;
            height: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        ::v-deep(.fc-button-group){
            padding: 0 5px;
        }
        ::v-deep(.fc-toolbar-title){
            font-size: 14px;
            text-align: center;
        }
    }
    .print-future-event{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 50px;
        user-select: none;
        cursor: pointer;
        color: var(--blue);
        font-size: 30px;
    }



    

 </style>  